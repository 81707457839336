import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { FaBars } from "react-icons/fa";
import { guideMenu, studentMenu } from "../../config/sidebarItems";
import { Link } from "react-router-dom";
import user from "../../assets/msd.jpg";
import { colors } from "../../config/constants";
import { useSelector, useDispatch } from "react-redux";
import { StateProps } from "../../redux/reducer";
import { updateSidebarTab } from "../../redux/action";
import logo from "../../assets/banner.png";

type Anchor = "top" | "left" | "bottom" | "right";
export default function ResponsiveSidebar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const dispatch = useDispatch();
  const activeTab = useSelector((state: StateProps) => state.defaultSideBarTab);

  React.useEffect(() => {
    const currentpath = window.location.href.split("/");
    if (currentpath[currentpath.length - 1] === "dashboard") {
      dispatch(updateSidebarTab("Dashboard"));
    }
  }, []);

  const handleOnclick = (item: string) => {
    dispatch(updateSidebarTab(item));
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="bg-white">
        <div className="flex items-center justify-center my-4">
          <img src={logo} className="h-[48px] w-full" />
        </div>
        <div className="w-60 shadow-md px-4 pb-4">
          <div className={`flex flex-col items-center ${colors.primary}`}>
            <div>
              <img
                src={user}
                width={100}
                height={100}
                className="rounded-full border-2 my-2"
              />
            </div>
            <div className="text-white">Welcome</div>
            <div className="text-white font-bold">Sunil Kumar</div>
          </div>

          {studentMenu.map((item, index) => {
            return (
              <Link
                to={item.url}
                onClick={() => handleOnclick(item.name)}
                key={index}
                className={`${
                  activeTab === item.name
                    ? `${colors.primary} text-white`
                    : `bg-slate-200`
                } text-[gray] hover:text-white hover:${
                  colors.primary
                } flex  items-center p-2 my-2 cursor-pointer rounded-sm`}
              >
                <div className="mr-4 "> {item.icon}</div>
                <div className="text-sm">{item.name}</div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {/* {(['left', 'right', 'top', 'bottom'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))} */}

      <FaBars
        onClick={toggleDrawer("left", true)}
        size={22}
        className="ml-2 sm:hidden"
      />

      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        className="sm:hidden"
      >
        {list("left")}
      </Drawer>
    </div>
  );
}
