export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const UPDATESIDEBARTAB = "UPDATESIDEBARTAB";
export const UPDATE_BREADCRUMB = "UPDATE_BREADCRUMB";
export const UPDATE_IS_LOADER = "UPDATE_IS_LOADER";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";
export const UPDATE_TAB = "UPDATE_TAB";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";

export function increment() {
  return {
    type: INCREMENT,
  };
}
export function decrement() {
  return {
    type: DECREMENT,
  };
}
export function update_breadcrumb(crumbs: any) {
  return {
    type: UPDATE_BREADCRUMB,
    payload: crumbs,
  };
}

export function updateSidebarTab(tab: string) {
  return {
    type: UPDATESIDEBARTAB,
    payload: tab,
  };
}

export function updateIsLoading(status: boolean) {
  return {
    type: UPDATE_IS_LOADER,
    payload: status,
  };
}

export function updateToken(token: any) {
  return {
    type: UPDATE_TOKEN,
    payload: token,
  };
}
export function updateUserInformation(info: any) {
  return {
    type: UPDATE_USER_INFORMATION,
    payload: info,
  };
}
export function updateTab(tab: any) {
  return {
    type: UPDATE_TAB,
    payload: tab,
  };
}
export function updateRole(role: string) {
  return {
    type: UPDATE_USER_ROLE,
    payload: role,
  };
}
