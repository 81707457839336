import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";

const GuideDetailsForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = useParams();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [state, setState] = useState<any>({
    Aadhaar: "",
    Address: "",
    Cand_id: userinformation.UserID,
    DOJ: "",
    DOR: "",
    GuideId: "",
    Name: "",
    PAN: "",
    Qualification: "",
    areaOfresearch: "",
    emailId: "",
    googleScholarId: "",
    mobileNumber: "",
    numberOfpublications: "",
    orchidId: "",
    totalExperience: "",
    vidwanId: "",
    yearOfaward: "",
  });
  const {
    Aadhaar,
    Address,
    Cand_id,
    DOJ,
    DOR,
    GuideId,
    Name,
    PAN,
    Qualification,
    areaOfresearch,
    emailId,
    googleScholarId,
    mobileNumber,
    numberOfpublications,
    orchidId,
    totalExperience,
    vidwanId,
    yearOfaward,
  } = state;
  const validateGuideForm = () => {
    const error: any = {};
    if (!Aadhaar) {
      error.Aadhaar = "Please enter Aadhaar";
    }
    if (!Address) {
      error.Address = "Please enter Address";
    }
    // if (!Cand_id) {
    //   error.Cand_id = "Please enter Cand_id";
    // }
    if (!DOJ) {
      error.DOJ = "Please enter DOJ";
    }
    if (!DOR) {
      error.DOR = "Please enter DOR";
    }
    if (!Name) {
      error.Name = "Please enter Name";
    }
    if (!PAN) {
      error.PAN = "Please enter PAN";
    }
    if (!Qualification) {
      error.Qualification = "Please enter Qualification";
    }
    if (!areaOfresearch) {
      error.areaOfresearch = "Please enter areaOfresearch";
    }
    if (!emailId) {
      error.emailId = "Please enter emailId";
    }
    if (!googleScholarId) {
      error.googleScholarId = "Please enter googleScholarId";
    }
    if (!mobileNumber) {
      error.mobileNumber = "Please enter mobileNumber";
    }
    if (!numberOfpublications) {
      error.numberOfpublications = "Please enter numberOfpublications";
    }
    if (!orchidId) {
      error.orchidId = "Please enter orchidId";
    }
    if (!totalExperience) {
      error.totalExperience = "Please enter totalExperience";
    }
    if (!vidwanId) {
      error.vidwanId = "Please enter vidwanId";
    }
    if (!yearOfaward) {
      error.yearOfaward = "Please enter yearOfaward";
    }
    return error;
  };
  const handleChangeText = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    setFormErrors(validateGuideForm());
    setIsSubmit(true);
  };
  useEffect(() => {
    if (action.action === "edit") {
      GetGuideDetails();
    } else {
      profileInformation();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (action.action === "edit") {
        guideDetailsUpdate();
      } else {
        guideDetailsSubmit();
      }
    }
  }, [formErrors]);

  const profileInformation = async () => {
    setIsLoading(true);
    await instance
      .post("/account/GetUserProfile", { ID: userinformation.UserID })
      .then((response) => {
        setState({
          ...state,
          Name: response.data.UserName,
          emailId: response.data.EmailID,
          mobileNumber: response.data.PhoneNumber,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const guideDetailsSubmit = async () => {
    setIsLoading(true);

    await instance
      .post(`/Guide/GuideDetails`, state)
      .then((response) => {
        if (response.status === 200) {
          console.log("guide-submit", response);
          alert("Details submitted successfully!");
          navigate("/guide/guide_profile");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };
  const GetGuideDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Guide/GetGuideDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);

          console.log("guideDetails", response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const guideDetailsUpdate = async () => {
    setIsLoading(true);

    await instance
      .post(
        `/Guide/GuideDetailsUpdated?UserId=${userinformation.UserID}`,
        state
      )
      .then((response) => {
        if (response.status === 200) {
          alert("Profile details updated successfully!");
          navigate("/guide/guide_profile");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };
  return (
    // <>
    //   {isLoading ? (
    //     <ActivityIndicator />
    //   ) : (
    <div className="bg-white shadow-lg p-4">
      <div className="px-1">
        <div className="font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
          Guide Details
        </div>
      </div>
      <div className="my-1 border-b p-2">
        <div className="font-semibold text-lg">Guide Details</div>
      </div>
      <div className="flex px-2">
        <div className="sm:flex sm:space-x-10   ">
          <div className="w-full">
            <div className="my-1">
              <Input
                lable="Name"
                type="text"
                name="Name"
                value={Name}
                onChange={handleChangeText}
                error={formErrors.Name}
              />
            </div>
            <div className="my-1 ">
              <Input
                lable="PAN"
                type="text"
                name="PAN"
                value={PAN}
                onChange={handleChangeText}
                error={formErrors.PAN}
              />
            </div>
            <div className="my-1 ">
              <Input
                lable="Aadhaar Number"
                type="text"
                name="Aadhaar"
                value={Aadhaar}
                onChange={handleChangeText}
                error={formErrors.Aadhaar}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Address"
                type="text"
                name="Address"
                value={Address}
                onChange={handleChangeText}
                error={formErrors.Address}
              />
            </div>
            {/* <div className="my-1">
              <Input
                lable="Candidate ID"
                type="text"
                name="Cand_id"
                value={Cand_id}
                onChange={handleChangeText}
                error={formErrors.Cand_id}
              />
            </div> */}

            <div className="my-1">
              <Input
                lable="Qualification"
                type="text"
                name="Qualification"
                value={Qualification}
                onChange={handleChangeText}
                error={formErrors.Qualification}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Email"
                type="text"
                name="emailId"
                value={emailId}
                onChange={handleChangeText}
                error={formErrors.emailId}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Date of Joining"
                type="date"
                name="DOJ"
                value={DOJ}
                onChange={handleChangeText}
                error={formErrors.DOJ}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Date of Recognition"
                type="date"
                name="DOR"
                value={DOR}
                onChange={handleChangeText}
                error={formErrors.DOR}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="my-1">
              <Input
                lable="Area of Reasearch"
                type="text"
                name="areaOfresearch"
                value={areaOfresearch}
                onChange={handleChangeText}
                error={formErrors.areaOfresearch}
              />
            </div>

            <div className="my-1">
              <Input
                lable="Google Scholar Id"
                type="text"
                name="googleScholarId"
                value={googleScholarId}
                onChange={handleChangeText}
                error={formErrors.googleScholarId}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Mobile number"
                type="text"
                name="mobileNumber"
                value={mobileNumber}
                onChange={handleChangeText}
                error={formErrors.mobileNumber}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Number of Publication"
                type="text"
                name="numberOfpublications"
                value={numberOfpublications}
                onChange={handleChangeText}
                error={formErrors.numberOfpublications}
              />
            </div>
            {/* <div className="my-1 ">
              <Input
                lable="Guide Id"
                type="text"
                name="GuideId"
                value={GuideId}
                onChange={handleChangeText}
                error={formErrors.GuideId}
              />
            </div> */}

            <div className="my-1">
              <Input
                lable="Orchid Id"
                type="text"
                name="orchidId"
                value={orchidId}
                onChange={handleChangeText}
                error={formErrors.orchidId}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Total Experience"
                type="text"
                name="totalExperience"
                value={totalExperience}
                onChange={handleChangeText}
                error={formErrors.totalExperience}
              />
            </div>

            <div className="my-1">
              <Input
                lable="Vidwan Id"
                type="text"
                name="vidwanId"
                value={vidwanId}
                onChange={handleChangeText}
                error={formErrors.vidwanId}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Year of Award"
                type="text"
                name="yearOfaward"
                value={yearOfaward}
                onChange={handleChangeText}
                error={formErrors.yearOfaward}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-4 px-2 mt-5">
        <Button
          lable={action.action === "edit" ? "update" : "submit"}
          type="button"
          buttonType="primary"
          onClick={() => handleSubmit()}
        />
        <Button
          lable="cancel"
          type="button"
          buttonType="danger"
          onClick={() => navigate("/guide/guide_profile")}
        />
      </div>
    </div>
    //   )}
    // </>
  );
};

export default GuideDetailsForm;
