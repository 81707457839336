import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../service/service";
import { updateTab } from "../../../redux/action";
import instance from "../../../service/axiosInstane";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";

const StudentFeeform = () => {
  const dispatch = useDispatch();
  const action = useParams();
  const navigate = useNavigate();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState({
    Subject: "",
    CandId: userinformation.UserID,
    AreaOfResearch: "",
    ApplicationFee: "",
    RegistrationFee: "",
    CourseworkFee: "",
    IDCMFee: "",
    IIDCMFee: "",
    IYearFee: "",
    IIYearFee: "",
    IIIYearFee: "",
    IVYearFee: "",
    VYearFee: "",
    ThesisSubmissionFee: "",
    ViveFee: "",
    OthersFeeI: "",
    OthersFeeII: "",
    SubmittedOn: true,
  });

  const {
    Subject,
    AreaOfResearch,
    ApplicationFee,
    RegistrationFee,
    CourseworkFee,
    IDCMFee,
    IIDCMFee,
    IYearFee,
    IIYearFee,
    IIIYearFee,
    IVYearFee,
    VYearFee,
    ThesisSubmissionFee,
    ViveFee,
    OthersFeeI,
    OthersFeeII,
  } = state;

  const validateFeeDetails = () => {
    const error: any = {};
    if (!Subject) {
      error.Subject = "Please enter subject";
    }
    if (!AreaOfResearch) {
      error.AreaOfResearch = "Please enter area of research";
    }
    if (!ApplicationFee) {
      error.ApplicationFee = "Please enter application fee";
    }
    if (!RegistrationFee) {
      error.RegistrationFee = "Please enter registration Fee";
    }
    if (!CourseworkFee) {
      error.CourseworkFee = "Please enter coursework fee";
    }
    if (!IDCMFee) {
      error.IDCMFee = "Please enter I DCM fee";
    }
    if (!IIDCMFee) {
      error.IIDCMFee = "Please enter IIDCM  fee";
    }
    if (!IYearFee) {
      error.IYearFee = "Please enter IYear fee";
    }
    if (!IIYearFee) {
      error.IIYearFee = "Please enter IIYear fee";
    }
    if (!IIIYearFee) {
      error.IIIYearFee = "Please enter IIIYear fee";
    }
    if (!IVYearFee) {
      error.IVYearFee = "Please enter IVYear fee";
    }
    if (!VYearFee) {
      error.VYearFee = "Please enter VYear fee";
    }
    if (!ThesisSubmissionFee) {
      error.ThesisSubmissionFee = "Please enter thesis submission fee";
    }
    if (!ViveFee) {
      error.ViveFee = "Please enter vive fee";
    }
    if (!OthersFeeI) {
      error.OthersFeeI = "Please enter others fee I";
    }
    if (!ViveFee) {
      error.OthersFeeII = "Please enter others fee II";
    }
    return error;
  };
  const handleChangeText = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const hadleSubmit = () => {
    setFormErrors(validateFeeDetails());
    setIsSubmit(true);
  };
  useEffect(() => {
    setIsLoading(true);
    if (action.action === "edit") {
      getFeeDetails();
      setIsLoading(false);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (action.action === "edit") {
        feeDetailsUpdate();
      } else {
        onFeeSubmit();
      }
    }
  }, [formErrors]);

  const onFeeSubmit = () => {
    setIsLoading(true);
    instance
      .post("/Students/FeeDetails", state)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateTab(3));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const feeDetailsUpdate = () => {
    setIsLoading(true);

    instance
      .post(
        `/Students/UpdatedFeeDetails?UserId=${userinformation.UserID}`,
        state
      )
      .then((response) => {
        if (response.status === 200) {
          navigate("/student/student_profile");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const getFeeDetails = () => {
    setIsLoading(true);

    instance
      .get(`/Students/GetFeeDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  return (
    // <>
    //   {isLoading ? (
    //     <ActivityIndicator />
    //   ) : (
    <div>
      <div className="my-2 border-b-2 p-2">
        <div className="font-semibold text-lg">Fee Details</div>
      </div>
      <div className="flex px-2">
        <div className="sm:flex  sm:space-x-10   ">
          <div className="w-full">
            <div className="my-1 ">
              <Input
                lable="Subject"
                type="text"
                name="Subject"
                value={Subject}
                onChange={handleChangeText}
                error={formErrors.Subject}
              />
            </div>

            <div className="my-1">
              <Input
                lable="Area Of Research,"
                type="text"
                name="AreaOfResearch"
                value={AreaOfResearch}
                onChange={handleChangeText}
                error={formErrors.AreaOfResearch}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Application Fee"
                type="text"
                name="ApplicationFee"
                value={ApplicationFee}
                onChange={handleChangeText}
                error={formErrors.ApplicationFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Registration Fee,"
                type="text"
                name="RegistrationFee"
                value={RegistrationFee}
                onChange={handleChangeText}
                error={formErrors.RegistrationFee}
              />
            </div>
            <div className="my-1 ">
              <Input
                lable="Coursework Fee"
                type="text"
                name="CourseworkFee"
                value={CourseworkFee}
                onChange={handleChangeText}
                error={formErrors.CourseworkFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="IDCM Fee"
                type="text"
                name="IDCMFee"
                value={IDCMFee}
                onChange={handleChangeText}
                error={formErrors.IDCMFee}
              />
            </div>
            <div className="my-1 ">
              <Input
                lable="IIDCM Fee"
                type="text"
                name="IIDCMFee"
                value={IIDCMFee}
                onChange={handleChangeText}
                error={formErrors.IIDCMFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="I Year Fee"
                type="text"
                name="IYearFee"
                value={IYearFee}
                onChange={handleChangeText}
                error={formErrors.IYearFee}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="my-1">
              <Input
                lable="II Year Fee"
                type="text"
                name="IIYearFee"
                value={IIYearFee}
                onChange={handleChangeText}
                error={formErrors.IIYearFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="III Year Fee"
                type="text"
                name="IIIYearFee"
                value={IIIYearFee}
                onChange={handleChangeText}
                error={formErrors.IIIYearFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="IV Year Fee"
                type="text"
                name="IVYearFee"
                value={IVYearFee}
                onChange={handleChangeText}
                error={formErrors.IVYearFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="V Year Fee"
                type="text"
                name="VYearFee"
                value={VYearFee}
                onChange={handleChangeText}
                error={formErrors.VYearFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Thesis Submission Fee"
                type="text"
                name="ThesisSubmissionFee"
                value={ThesisSubmissionFee}
                onChange={handleChangeText}
                error={formErrors.ThesisSubmissionFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Vive Fee,"
                type="text"
                name="ViveFee"
                value={ViveFee}
                onChange={handleChangeText}
                error={formErrors.ViveFee}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Others FeeI"
                type="text"
                name="OthersFeeI"
                value={OthersFeeI}
                onChange={handleChangeText}
                error={formErrors.OthersFeeI}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Date of Registration"
                type="text"
                name="OthersFeeII"
                value={OthersFeeII}
                onChange={handleChangeText}
                error={formErrors.OthersFeeII}
              />
            </div>
          </div>
        </div>
      </div>
      {action.action === "edit" ? (
        <div className="flex items-center px-2 space-x-4 mt-5">
          <Button
            lable="Update"
            type="button"
            buttonType="primary"
            onClick={() => hadleSubmit()}
          />
          <Button
            lable="cancel"
            type="button"
            buttonType="danger"
            onClick={() => navigate("/student/student_profile")}
          />{" "}
        </div>
      ) : (
        <div className="flex items-center  space-x-4 mt-10">
          <Button
            lable="Submit"
            type="button"
            buttonType="primary"
            onClick={() => hadleSubmit()}
          />
          <Button
            lable="cancel"
            type="button"
            buttonType="danger"
            onClick={() => navigate("/student/student_profile")}
          />{" "}
        </div>
      )}
    </div>
    //   )}
    // </>
  );
};

export default StudentFeeform;
