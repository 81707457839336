import React, { useEffect, useState } from "react";
import MuiTable from "../../../components/MuiTable/MuiTable";
import { data } from "../../../service/json";
import instance from "../../../service/axiosInstane";
import { useDispatch, useSelector } from "react-redux";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import { update_breadcrumb } from "../../../redux/action";

const Students = () => {
  const dispatch = useDispatch();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    { id: "UserName", label: "UserName", minWidth: 70, align: "left" },
    {
      id: "Email",
      label: "Email",
      minWidth: 170,
      align: "left",
    },
    {
      id: "PhoneNumber",
      label: "PhoneNumber",
      minWidth: 170,
      align: "left",
    },

    {
      id: "CreatedOn",
      label: "Registerd Date",
      minWidth: 70,
      align: "left",
    },
    {
      id: "UpdatedDate",
      label: "Updated Date",
      minWidth: 70,
      align: "left",
    },
  ];
  useEffect(() => {
    getStudentList();

    const breadcrumb = [{ path: "/guide/students", name: "Student List" }];
    dispatch(update_breadcrumb(breadcrumb));
  }, []);
  const getStudentList = async () => {
    setIsLoading(true);
    await instance
      .get(`Guide/GuideGetStudent?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <div className="bg-white p-4 shadow-lg">
          <div className="px-1 mb-2">
            <div className="font-semibold text-[24px] text-sky-700">
              Students List
            </div>
          </div>
          <MuiTable columns={columns} rows={state} searchColumn={"UserName"} />
        </div>
      )}
    </>
  );
};

export default Students;
