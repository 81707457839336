import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Dashboard from "./screens/Dashboard/Dashboard";
import Login from "./screens/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import Profile from "./screens/Profile/Profile";
import Changepassword from "./screens/ChangePassword/ChangePassword";
import BreadCrumb from "./components/BreadCrumb/BreadCrumb";
import ActivityIndicator from "./components/ActivityIndicator/ActivityIndicator";

import Register from "./screens/Register/Register";
import { Suspense } from "react";
import Message from "./screens/Message/Message";
import StudentHome from "./screens/Students/StudentHome/StudentHome";
import StudentNotification from "./screens/Students/StudentNotification/StudentNotification";
import StudentDetailForm from "./screens/Students/StudentDetailForm/StudentDetailForm";
import StudentProfile from "./screens/Students/StudentProfile/StudentProfile";
import GuideNotification from "./screens/Guides/GuideNotification/GuideNotification";
import GuideHome from "./screens/Guides/GuideHome/GuideHome";
import GuideProfile from "./screens/Guides/GuideProfile/GuideProfile";
import Students from "./screens/Guides/Students/Students";
import GuideDetailsForm from "./screens/Guides/GuideDetailsForm/GuideDetailsForm";

const Router = () => {
  const Layout = () => (
    <div className="bg-slate-100 relative min-h-screen">
      <div className="sticky top-0 z-50">
        <Header />
      </div>
      <div className="fixed top-16">
        <Sidebar />
      </div>

      <div className="sm:ml-60 sm:m-4">
        <div className="sm:m-4">
          <BreadCrumb />
        </div>
        <div className="sm:m-4">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<ActivityIndicator />}>
          <Routes>
            <Route element={<Layout />}>
              <Route path="student/student_home" element={<StudentHome />} />
              <Route
                path="student/student_profile_form/:action/:tab_id"
                element={<StudentDetailForm />}
              />
              <Route
                path="student/student_profile"
                element={<StudentProfile />}
              />
              <Route
                path="student/student_notification"
                element={<StudentNotification />}
              />

              <Route
                path="guide/guide_notification"
                element={<GuideNotification />}
              />
              <Route path="guide/guide_home" element={<GuideHome />} />
              <Route path="guide/guide_profile" element={<GuideProfile />} />
              <Route path="guide/students" element={<Students />} />
              <Route
                path="guide/guide_details_form/:action"
                element={<GuideDetailsForm />}
              />
            </Route>

            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default Router;
