import React from "react";
import {
  FaHome,
  FaUserTie,
  FaUserFriends,
  FaGlobe,
  FaListAlt,
  FaKey,
  FaFileWord,
} from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
// export const sidebarItems = [
//   {
//     name: "Dashboard",
//     url: "/dashboard",
//     icon: <FaHome size={20} />,
//   },
//   {
//     name: "Student",
//     url: "/students",
//     icon: <FaUserTie size={20} />,
//   },
//   {
//     name: "Guide",
//     url: "/guide",
//     icon: <FaUserFriends size={20} />,
//   },
//   {
//     name: "Message",
//     url: "/messages",
//     icon: <FaGlobe size={20} />,
//   },
//   {
//     name: "Profile",
//     url: "/profile",
//     icon: <FaListAlt size={20} />,
//   },
// ];

export const studentMenu = [
  {
    name: "Dashboard",
    url: "/student/student_home",
    icon: <FaHome size={20} />,
  },
  {
    name: "Notification",
    url: "/student/student_notification",
    icon: <IoIosNotifications size={20} />,
  },

  {
    name: "Profile",
    url: "/student/student_profile",
    icon: <FaListAlt size={20} />,
  },
];

export const guideMenu = [
  {
    name: "Dashboard",
    url: "/guide/guide_home",
    icon: <FaHome size={20} />,
  },
  {
    name: "Notification",
    url: "/guide/guide_notification",
    icon: <IoIosNotifications size={20} />,
  },
  {
    name: "Students",
    url: "/guide/students",
    icon: <FaListAlt size={20} />,
  },

  {
    name: "Profile",
    url: "/guide/guide_profile",
    icon: <FaListAlt size={20} />,
  },
];
