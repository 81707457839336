import React, { ReactNode } from "react";

interface ActionProps {
  lable: string;
  type: "button" | "submit" | "reset";
  buttonType: string;
  onClick?: () => void;
  icon?: ReactNode;
}
const Button = (props: ActionProps) => {
  const { lable, onClick, type, buttonType, icon } = props;
  const actionColor = () => {
    if (buttonType === "primary") {
      return "bg-[#1520A6]";
    }
    if (buttonType === "danger") {
      return "bg-red-700";
    }
    if (buttonType === "warning") {
      return "bg-yellow-500";
    }
  };
  return (
    <button
      type={type}
      className={`${actionColor()} py-2 rounded-sm text-white flex items-center text-sm px-3`}
      onClick={onClick}
    >
      {icon && <div className="mr-1">{icon}</div>}
      <div className="text-sm font-semibold">{lable.toUpperCase()}</div>
    </button>
  );
};

export default Button;
