import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../service/service";
import { updateTab } from "../../../redux/action";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";

const StudentReportfrom = () => {
  const dispatch = useDispatch();
  const action = useParams();
  const navigate = useNavigate();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [state, setState] = useState({
    CandId: userinformation.UserID,
    I: "",
    II: "",
    III: "",
    IV: "",
    V: "",
    VI: "",
    VII: "",
    VIII: "",
    SubmissionDate: "",
    ExaminerDetails: "",
    ExaminerI: "",
    ExaminerII: "",
    ExaminerIII: "",
    PaperPublications: "",
    NationalJournals: "",
    InternationalJournals: "",
    Patents: "",
    Resubmitted: "",
    SubmittedOn: true,
    VivaDate: "",
    ConvocationDate: "",
  });

  const {
    I,
    II,
    III,
    IV,
    V,
    VI,
    VII,
    VIII,
    SubmissionDate,
    ExaminerDetails,
    ExaminerI,
    ExaminerII,
    ExaminerIII,
    PaperPublications,
    NationalJournals,
    InternationalJournals,
    Patents,
    Resubmitted,
    VivaDate,
    ConvocationDate,
  } = state;
  const validateReportData = () => {
    const error: any = {};
    if (!I) {
      error.I = "Please enter I";
    }
    if (!II) {
      error.II = "Please enter II";
    }
    if (!III) {
      error.III = "Please enter III";
    }
    if (!IV) {
      error.IV = "Please enter IV";
    }
    if (!V) {
      error.V = "Please enter V";
    }
    if (!VI) {
      error.VI = "Please enter VI";
    }
    if (!VII) {
      error.VII = "Please enter VII";
    }
    if (!VIII) {
      error.VIII = "Please enter VIII";
    }
    if (!SubmissionDate) {
      error.SubmissionDate = "Please enter SubmissionDate";
    }
    if (!ExaminerDetails) {
      error.ExaminerDetails = "Please enter ExaminerDetails";
    }
    if (!ExaminerI) {
      error.ExaminerI = "Please enter ExaminerI";
    }
    if (!ExaminerII) {
      error.ExaminerII = "Please enter ExaminerII";
    }
    if (!ExaminerIII) {
      error.ExaminerIII = "Please enter ExaminerIII";
    }
    if (!PaperPublications) {
      error.PaperPublications = "Please enter PaperPublications";
    }
    if (!NationalJournals) {
      error.NationalJournals = "Please enter NationalJournals";
    }
    if (!InternationalJournals) {
      error.InternationalJournals = "Please enter InternationalJournals";
    }
    if (!Patents) {
      error.Patents = "Please enter Patents";
    }
    if (!Resubmitted) {
      error.Resubmitted = "Please enter Resubmitted";
    }
    if (!VivaDate) {
      error.VivaDate = "Please enter VivaDate";
    }
    if (!ConvocationDate) {
      error.ConvocationDate = "Please enter ConvocationDate";
    }
    return error;
  };
  const handleChangeText = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const hadleSubmit = () => {
    setFormErrors(validateReportData());
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (action.action === "edit") {
        onReportUpdate();
      } else {
        onReportSubmit();
      }
    }
  }, [formErrors]);

  useEffect(() => {
    if (action.action === "edit") {
      setIsLoading(true);
      getReportDetails();
      setIsLoading(false);
    }
  }, [window.location.href]);

  const getReportDetails = async () => {
    setIsLoading(true);
    await instance
      .get(`/Students/GetReportDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };
  const onReportUpdate = async () => {
    setIsLoading(true);
    await instance
      .post(
        `/Students/UpdatedReportDetails?UserId=${userinformation.UserID}`,
        state
      )
      .then((response) => {
        if (response.status === 200) {
          navigate("/student/student_profile");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const onReportSubmit = async () => {
    setIsLoading(true);
    await instance
      .post("/Students/ReportDetails", state)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateTab(0));
          navigate("/student/student_home");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };
  return (
    // <>
    //   {isLoading ? (
    //     <ActivityIndicator />
    //   ) : (
    <div>
      <div className="my-2 border-b-2 p-2">
        <div className="font-semibold text-lg">Report Details</div>
      </div>
      <div className="flex px-2">
        <div className="sm:flex sm:space-x-10">
          <div className="w-full">
            <div className="my-1 ">
              <Input
                lable="I Report"
                type="text"
                name="I"
                value={I}
                onChange={handleChangeText}
                error={formErrors.I}
              />
            </div>

            <div className="my-1">
              <Input
                lable="II Report"
                type="text"
                name="II"
                value={II}
                onChange={handleChangeText}
                error={formErrors.II}
              />
            </div>
            <div className="my-1">
              <Input
                lable="III Report"
                type="text"
                name="III"
                value={III}
                onChange={handleChangeText}
                error={formErrors.III}
              />
            </div>
            <div className="my-1">
              <Input
                lable="IV Report"
                type="text"
                name="IV"
                value={IV}
                onChange={handleChangeText}
                error={formErrors.IV}
              />
            </div>
            <div className="my-1 ">
              <Input
                lable="V Report"
                type="text"
                name="V"
                value={V}
                onChange={handleChangeText}
                error={formErrors.V}
              />
            </div>
            <div className="my-1">
              <Input
                lable="VI Report"
                type="text"
                name="VI"
                value={VI}
                onChange={handleChangeText}
                error={formErrors.VI}
              />
            </div>
            <div className="my-1 ">
              <Input
                lable="VII Report"
                type="text"
                name="VII"
                value={VII}
                onChange={handleChangeText}
                error={formErrors.VII}
              />
            </div>
            <div className="my-1">
              <Input
                lable="VIII Report"
                type="text"
                name="VIII"
                value={VIII}
                onChange={handleChangeText}
                error={formErrors.VIII}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Submission Date"
                type="date"
                name="SubmissionDate"
                value={SubmissionDate}
                onChange={handleChangeText}
                error={formErrors.SubmissionDate}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Examiner Details"
                type="text"
                name="ExaminerDetails"
                value={ExaminerDetails}
                onChange={handleChangeText}
                error={formErrors.ExaminerDetails}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="my-1">
              <Input
                lable="Examiner I"
                type="text"
                name="ExaminerI"
                value={ExaminerI}
                onChange={handleChangeText}
                error={formErrors.ExaminerI}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Examiner II"
                type="text"
                name="ExaminerII"
                value={ExaminerII}
                onChange={handleChangeText}
                error={formErrors.ExaminerII}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Examiner III"
                type="text"
                name="ExaminerIII"
                value={ExaminerIII}
                onChange={handleChangeText}
                error={formErrors.ExaminerIII}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Paper Publications"
                type="text"
                name="PaperPublications"
                value={PaperPublications}
                onChange={handleChangeText}
                error={formErrors.PaperPublications}
              />
            </div>
            <div className="my-1">
              <Input
                lable="National Journals"
                type="text"
                name="NationalJournals"
                value={NationalJournals}
                onChange={handleChangeText}
                error={formErrors.NationalJournals}
              />
            </div>
            <div className="my-1">
              <Input
                lable="International Journals"
                type="text"
                name="InternationalJournals"
                value={InternationalJournals}
                onChange={handleChangeText}
                error={formErrors.InternationalJournals}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Patents"
                type="text"
                name="Patents"
                value={Patents}
                onChange={handleChangeText}
                error={formErrors.Patents}
              />
            </div>
            <div className="my-1">
              <Input
                lable="Resubmitted"
                type="text"
                name="Resubmitted"
                value={Resubmitted}
                onChange={handleChangeText}
                error={formErrors.Resubmitted}
              />
            </div>
            <div className="my-1">
              <Input
                lable="VivaDate"
                type="date"
                name="VivaDate"
                value={VivaDate}
                onChange={handleChangeText}
                error={formErrors.VivaDate}
              />
            </div>
            <div className="my-1">
              <Input
                lable="ConvocationDate"
                type="date"
                name="ConvocationDate"
                value={ConvocationDate}
                onChange={handleChangeText}
                error={formErrors.ConvocationDate}
              />
            </div>
          </div>
        </div>
      </div>
      {action.action === "edit" ? (
        <div className="flex items-center space-x-4 mt-5 px-2">
          <Button
            lable="update"
            type="button"
            buttonType="primary"
            onClick={hadleSubmit}
          />
          <Button
            lable="cancel"
            type="button"
            buttonType="danger"
            onClick={() => navigate("/student/student_profile")}
          />{" "}
        </div>
      ) : (
        <div className="flex items-center space-x-4 mt-5 px-2">
          <Button
            lable="Submit"
            type="button"
            buttonType="primary"
            onClick={hadleSubmit}
          />
          <Button
            lable="cancel"
            type="button"
            buttonType="danger"
            onClick={() => navigate("/student/student_profile")}
          />{" "}
        </div>
      )}
    </div>
    //   )}
    // </>
  );
};

export default StudentReportfrom;
