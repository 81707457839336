import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import React, { useState, useEffect } from "react";
import image1 from "../../../assets/slider/1.jpg";
import image2 from "../../../assets/slider/2.jpg";
import image3 from "../../../assets/slider/3.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateIsLoading, update_breadcrumb } from "../../../redux/action";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
const StudentHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [completedProfile, setCompletedProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const slideImages = [
    { banner: image1 },
    { banner: image2 },
    { banner: image3 },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [count, setCount] = useState(0);
  const tabcheck = useSelector((state: any) => state.currentTab);
  const username = useSelector((state: any) => state.userInfo.UserName);
  console.log(tabcheck, "tabcheck");

  const goToNext = () => {
    const isLastSlide = currentIndex === slideImages.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slideImages.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      goToNext();
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    getReportDetails();
    const breadcrumb = [{ path: "/student/student_home", name: "Dashboard" }];
    dispatch(update_breadcrumb(breadcrumb));
    dispatch(updateIsLoading(false));
  }, []);

  const getReportDetails = async () => {
    setIsLoading(true);
    await instance
      .get(`/Students/GetReportDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          console.log("kkkk", response.data.SubmittedOn);
          setCompletedProfile(response.data.SubmittedOn);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  return (
    <>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <div className="bg-white shadow-lg p-4">
          <div className="sm:flex items-center justify-between">
            <div className="font-semibold text-[24px] text-sky-700 uppercase">
              Welcome {username},
            </div>

            {completedProfile == null && (
              <div
                onClick={() =>
                  navigate("/student/student_profile_form/create/new_entry")
                }
                className="bg-[gold] w-fit p-2 rounded-md font-semibold cursor-pointer"
              >
                Complete your Profile
              </div>
            )}
          </div>
          <div className="flex items-center justify-center relative mt-4">
            <div
              onClick={() => goToNext()}
              className="absolute right-44 text-[50px] font-mono text-white cursor-pointer"
            >
              {">"}
            </div>
            <div
              onClick={() => goToPrevious()}
              className="absolute left-44 text-[50px] font-mono text-white cursor-pointer"
            >
              {"<"}
            </div>
            <img
              src={slideImages[currentIndex].banner}
              className="shadow-lg h-60 w-full"
            />
          </div>
          <div className="mt-4">
            <div className="bg-[#02497f] p-2 font-semibold text-white text-lg">
              Notification
            </div>
            <div>
              <li className="font-semibold my-1">
                International Conference on Pervasive Computing and Social
                Networking on June 20
              </li>
              <li className="font-semibold my-1">
                International Yoga Day on June 23
              </li>
              <li className="font-semibold my-1">
                Recruit popular speakers or host TED Talks on July 03
              </li>
              <li className="font-semibold my-1">
                Throw an event to kick off a mentorship program on July 13
              </li>
              <li className="font-semibold my-1">
                Help students relax with free de-stressing activities on Aug 09
              </li>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentHome;
