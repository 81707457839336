import React, { useEffect, useState } from "react";
import studentPhoto from "../../../assets/student.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateIsLoading, update_breadcrumb } from "../../../redux/action";
import instance from "../../../service/axiosInstane";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";

const StudentProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const option = [
    { id: 1, label: "Profile", onClick: () => setTab(1) },
    { id: 2, label: "Fee", onClick: () => setTab(2) },
    { id: 3, label: "Reasearch", onClick: () => setTab(3) },
    { id: 4, label: "Report", onClick: () => setTab(4) },
  ];
  const [tab, setTab] = useState(1);
  const userinformation = useSelector((state: any) => state.userInfo);
  const [basicDetails, setBasicDetails] = useState<any>({});
  const [researchDetails, setReSearchDetails] = useState<any>({});
  const [reportDetails, setReportDetails] = useState<any>({});
  const [feeDetails, setFeeDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [profileStatus, setProfileStatus] = useState<any>();

  useEffect(() => {
    getBasicDetails();
    getResearchDetails();
    getReportDetails();
    getFeeDetails();

    const breadcrumb = [
      { path: "/student/student_profile", name: "Student Profile" },
    ];
    dispatch(update_breadcrumb(breadcrumb));
  }, []);

  const getBasicDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetStudentDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setBasicDetails(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };
  const getResearchDetails = () => {
    setIsLoading(true);

    instance
      .get(`/Students/GetCourseWork?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          console.log("academy", response.data);
          setReSearchDetails(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const getReportDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetReportDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setReportDetails(response.data);
          setProfileStatus(response.data.SubmittedOn);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const getFeeDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetFeeDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setFeeDetails(response.data);
          console.log("Feee", response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const basicInformation = () => (
    <div>
      <div className="mt-2 sm:flex sm:space-x-6 text-[#02497f]">
        <div>
          <img src={studentPhoto} className="h-36 border-2 p-2" />
        </div>
        <div>
          <div className="flex space-x-2">
            <div className="font-semibold  w-48">Name</div>
            <div>: {basicDetails.CandName}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold w-48">Student Id</div>
            <div>: {userinformation.UserID}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Email</div>
            <div>: {basicDetails.EmailId}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Phone</div>
            <div>: {basicDetails.PhoneNumber}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Category</div>
            <div>: {basicDetails.Category}</div>
          </div>
        </div>
        <div>
          <div className="flex space-x-2 ">
            <div className="font-semibold  w-48">Registration No</div>
            <div>: {basicDetails.RegistrationNo}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Father's/Husband's Name</div>
            <div>: {basicDetails.FatherOrHusbandName}</div>
          </div>

          <div className="flex space-x-2 mt-1">
            <div className="font-semibold w-48">Date of Examination</div>
            <div>: {basicDetails.DateOfExamination}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">PAN</div>
            <div>: {basicDetails.PANNo}</div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="my-1 p-1 border-b-2">
        <div className="font-semibold text-md">More Information</div>
      </div>
      <div className="sm:flex">
        <div className="text-[#02497f] px-1 sm:w-1/2">
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Aadhar Nubmer</div>
            <div>: {basicDetails.AadharNo}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Qualified Exam</div>
            <div>: {basicDetails.NameOfQualifiedExam}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">CGPA / %</div>
            <div>: {basicDetails.CGPOrPercent}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">University</div>
            <div>: {basicDetails.NameOfUniversity}</div>
          </div>{" "}
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Year of Exam</div>
            <div>: {basicDetails.YearOfExam}</div>
          </div>
        </div>
        <div className="text-[#02497f] px-1">
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Permanent Address</div>
            <div>: {basicDetails.PermenentAddress}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold  w-48">Address for Communication</div>
            <div>: {basicDetails.CommunicationAddress}</div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <Button
          lable="Edit details"
          type="button"
          buttonType="primary"
          onClick={() => navigate("/student/student_profile_form/edit/1")}
        />
      </div>
    </div>
  );

  const academickDetails = () => (
    <div>
      <div className="mt-2 text-[#02497f]">
        <div className="sm:flex w-full items-center justify-between p-2 border-b-2">
          <div className="flex space-x-2">
            <div className="font-semibold ">Subject </div>
            <div>: {feeDetails.Subject}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">Area of Reasearch</div>
            <div>: {feeDetails.AreaOfResearch}</div>
          </div>
        </div>
        <div className="sm:flex sm:space-x-10 ">
          <div className="">
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Application Fee</div>
              <div>: {feeDetails.ApplicationFee}</div>
            </div>
            <div className="flex space-x-2 mt-2">
              <div className="font-semibold  w-48">Registration Fee</div>
              <div>: {feeDetails.RegistrationFee}</div>
            </div>
            <div className="flex space-x-2 mt-2">
              <div className="font-semibold  w-48">Coursework fee</div>
              <div>: {feeDetails.CourseworkFee}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">First DCM Fee</div>
              <div>: {feeDetails.IDCMFee}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Second DCM Fee</div>
              <div>: {feeDetails.IIDCMFee}</div>
            </div>
          </div>
          <div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">1st Year Fee</div>
              <div>: {feeDetails.IYearFee}</div>
            </div>
            <div className="flex space-x-2 mt-2">
              <div className="font-semibold  w-48">2nd Year Fee</div>
              <div>: {feeDetails.IIYearFee}</div>
            </div>
            <div className="flex space-x-2 mt-2">
              <div className="font-semibold  w-48">3rd Year Fee</div>
              <div>: {feeDetails.IIIYearFee}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">4th Year Fee</div>
              <div>: {feeDetails.IVYearFee}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">5th Year Fee</div>
              <div>: {feeDetails.VYearFee}</div>
            </div>
          </div>
          <div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Thesis Fee</div>
              <div>: {feeDetails.ThesisSubmissionFee}</div>
            </div>
            <div className="flex space-x-2 mt-2">
              <div className="font-semibold  w-48">Viva Fee</div>
              <div>: {feeDetails.ViveFee}</div>
            </div>
            <div className="flex space-x-2 mt-2">
              <div className="font-semibold  w-48">Others Fee-1</div>
              <div>: {feeDetails.OthersFeeI}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Others Fee-1</div>
              <div>: {feeDetails.OthersFeeII}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <Button
          lable="Edit details"
          type="button"
          buttonType="primary"
          onClick={() => navigate("/student/student_profile_form/edit/2")}
        />
      </div>
    </div>
  );

  const reasearchInformation = () => (
    <div>
      <div className="mt-2 text-[#02497f]">
        <div className="sm:flex w-full items-center justify-between p-2 border-b-2">
          <div className="flex space-x-2">
            <div className="font-semibold ">Subject 1 </div>
            <div>: {researchDetails.SubjectI}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">Subject 2</div>
            <div>: {researchDetails.SubjectII}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">Subject 3</div>
            <div>: {researchDetails.SubjectIII}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">Subject 4</div>
            <div>: {researchDetails.SubjectIV}</div>
          </div>
        </div>
        <div className=" ">
          <div className="font-semibold text-lg text-black my-2">
            Results of coursework with month and year
          </div>
        </div>

        <div className="sm:flex sm:space-x-10 ">
          <div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">ResultofCoursework</div>
              <div>: {researchDetails.ResultofCoursework}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">I DCM Meeting Date</div>
              <div>: {researchDetails.IDCMMeetingDate}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">II DCM Meeting Date</div>
              <div>: {researchDetails.IIDCMMeetingDate}</div>
            </div>
          </div>
          <div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">I DCM Examination</div>
              <div>: {researchDetails.IDCMExamination}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">II DCM Examination</div>
              <div>: {researchDetails.IIDCMExaminers}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">DCM Remark with title</div>
              <div>: {researchDetails.IDCMRemarkswithTitle}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <Button
          lable="Edit details"
          type="button"
          buttonType="primary"
          onClick={() => navigate("/student/student_profile_form/edit/3")}
        />
      </div>
    </div>
  );

  const reportInformation = () => (
    <div>
      <div className="mt-2 text-[#02497f]">
        <div className="sm:flex w-full items-center justify-between p-2 border-b-2">
          <div className="flex space-x-2">
            <div className="font-semibold ">1st </div>
            <div>: {reportDetails.I}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold "> 2nd</div>
            <div>: {reportDetails.II}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold "> 3rd</div>
            <div>: {reportDetails.III}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">4th</div>
            <div>: {reportDetails.IV}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">5th</div>
            <div>: {reportDetails.V}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">6th</div>
            <div>: {reportDetails.VI}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">7th</div>
            <div>: {reportDetails.VII}</div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="font-semibold ">8th</div>
            <div>: {reportDetails.VIII}</div>
          </div>
        </div>

        <div className="sm:flex sm:space-x-10 ">
          <div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">
                Date of Thesis submission
              </div>
              <div>: {reportDetails.SubmissionDate}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Examiner details</div>
              <div>: {reportDetails.ExaminerDetails}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Examiner -1</div>
              <div>: {reportDetails.ExaminerI}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Examiner -2</div>
              <div>: {reportDetails.ExaminerII}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Examiner -3</div>
              <div>: {reportDetails.ExaminerIII}</div>
            </div>
          </div>
          <div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Resubmitted</div>
              <div>: {reportDetails.Resubmitted}</div>
            </div>
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Date of viva</div>
              <div>: {reportDetails.VivaDate}</div>
            </div>
            {/* <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Date of Notification</div>
              <div>: No data</div>
            </div> */}
            {/* <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Final result</div>
              <div>: No data</div>
            </div> */}
            <div className="flex space-x-2 mt-2 ">
              <div className="font-semibold  w-48">Date of Convacation</div>
              <div>: {reportDetails.ConvocationDate}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <Button
          lable="Edit details"
          type="button"
          buttonType="primary"
          onClick={() => navigate("/student/student_profile_form/edit/4")}
        />
      </div>
    </div>
  );
  const subHeading = () => {
    switch (tab) {
      case 1:
        return "Profile";
      case 2:
        return "Fee";
      case 3:
        return "Reasearch";
      case 4:
        return "Report";
      default:
        return "No data";
    }
  };
  return (
    <>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <div className="bg-white shadow-lg p-4 min-h-[500px]">
          <div className="px-1">
            <div className="font-semibold text-[24px] text-sky-700">
              Student Profile
            </div>
          </div>
          {profileStatus === null ? (
            <div>Sorry! Profile is not Updated!</div>
          ) : (
            <>
              <div className="flex items-center justify-between cursor-pointer">
                <div className="hidden sm:block">
                  <div className="font-semibold text-lg">
                    {subHeading()} Information
                  </div>
                </div>
                <div className="flex items-center cursor-pointer">
                  {option.map((item, index) => {
                    return (
                      <div
                        className={`${
                          item.id === tab
                            ? "bg-[orange] text-white"
                            : "bg-[gold]"
                        }  py-1 px-4 border-r rounded-tl-md rounded-tr-md`}
                        onClick={item.onClick}
                      >
                        <div className="font-semibold">{item.label}</div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                <div className="bg-[gold] w-full h-1 rounded-lg"></div>
              </div>
              {tab === 1 && basicInformation()}
              {tab === 2 && academickDetails()}
              {tab === 3 && reasearchInformation()}
              {tab === 4 && reportInformation()}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default StudentProfile;
