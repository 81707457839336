import { AnyAction } from "redux";
import {
  INCREMENT,
  DECREMENT,
  UPDATESIDEBARTAB,
  UPDATE_BREADCRUMB,
  UPDATE_IS_LOADER,
  UPDATE_TOKEN,
  UPDATE_USER_INFORMATION,
  UPDATE_TAB,
  UPDATE_USER_ROLE,
} from "../action";

export interface StateProps {
  counter: number;
  isLoading: boolean;
  defaultSideBarTab: string;
  resposiveSidebar: boolean;
  crumbs: [{ path: string; name: string }];
  token: string;
  userInfo: [
    {
      UserID: number | string;
      UserName: string;
      EmailId: string;
      Expires_In: string;
      AccessToken: string;
      mobilePhone: string;
    }
  ];
  currentTab: number;
  userRole: string;
}

const initialState: StateProps = {
  counter: 1000,
  isLoading: true,
  defaultSideBarTab: "Dashboard",
  resposiveSidebar: false,
  crumbs: [{ path: "", name: "" }],
  token: "",
  userInfo: [
    {
      UserID: 0,
      UserName: "0",
      EmailId: "",
      Expires_In: "",
      AccessToken: "",
      mobilePhone: "",
    },
  ],
  currentTab: 1,
  userRole: "",
};

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case INCREMENT:
      return { ...state, counter: state.counter + 1 };
    case DECREMENT:
      return { ...state, counter: state.counter - 1 };
    case UPDATE_BREADCRUMB:
      return {
        ...state,
        crumbs: action.payload,
      };

    case UPDATESIDEBARTAB:
      return {
        ...state,
        defaultSideBarTab: action.payload,
      };
    case UPDATE_IS_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case UPDATE_USER_INFORMATION:
      return {
        ...state,
        userInfo: action.payload,
      };
    case UPDATE_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case UPDATE_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };

    default:
      return state;
  }
};
export default reducer;
