import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../service/service";
import { updateTab } from "../../../redux/action";
import instance from "../../../service/axiosInstane";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";

const StudenAcademicform = () => {
  const dispatch = useDispatch();
  const action = useParams();
  const navigate = useNavigate();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState({
    SubjectI: "",
    CandId: userinformation.UserID,
    SubjectII: "",
    SubjectIII: "",
    SubjectIV: "",
    ResultofCoursework: "",
    IDCMMeetingDate: "",
    IDCMExamination: "",
    IDCMRemarkswithTitle: "",
    IIDCMMeetingDate: "",
    IIDCMExaminers: "",
    IIDCMRemarks: "",
    SubmittedOn: true,
  });

  const {
    SubjectI,
    SubjectII,
    SubjectIII,
    SubjectIV,
    ResultofCoursework,
    IDCMMeetingDate,
    IDCMExamination,
    IDCMRemarkswithTitle,
    IIDCMMeetingDate,
    IIDCMExaminers,
    IIDCMRemarks,
  } = state;

  const validateAcademicDetails = () => {
    const error: any = {};
    if (!SubjectI) {
      error.SubjectI = "Please enter subject I";
    }
    if (!SubjectII) {
      error.SubjectII = "Please enter subject II";
    }
    if (!SubjectIII) {
      error.SubjectIII = "Please enter subject III";
    }
    if (!SubjectIV) {
      error.SubjectIV = "Please enter subject IV";
    }
    if (!ResultofCoursework) {
      error.ResultofCoursework = "Please enter result of course work";
    }
    if (!IDCMMeetingDate) {
      error.IDCMMeetingDate = "Please enter IDCM meeting date";
    }
    if (!IDCMExamination) {
      error.IDCMExamination = "Please enter IDCM examination";
    }
    if (!IDCMRemarkswithTitle) {
      error.IDCMRemarkswithTitle = "Please enter IDCM remarks with title";
    }
    if (!IIDCMMeetingDate) {
      error.IIDCMMeetingDate = "Please enter IIDCM meeting date";
    }
    if (!IIDCMExaminers) {
      error.IIDCMExaminers = "Please enter IIDCM examiners";
    }
    if (!IIDCMRemarks) {
      error.IIDCMRemarks = "Please enter IIDCM remarks";
    }
    return error;
  };
  const handleChangeText = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const hadleSubmit = () => {
    setFormErrors(validateAcademicDetails());
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (action.action === "edit") {
        onAcademicUpdate();
      } else {
        onAcademicSubmit();
      }
    }
  }, [formErrors]);

  useEffect(() => {
    if (action.action === "edit") {
      setIsLoading(true);
      getResearchDetails();
      setIsLoading(false);
    }
  }, [window.location.href]);

  const getResearchDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Students/GetCourseWork?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const onAcademicUpdate = () => {
    setIsLoading(true);

    instance
      .post(
        `/Students/UpdatedCourseWork?UserId=${userinformation.UserID}`,
        state
      )
      .then((response) => {
        if (response.status === 200) {
          navigate("/student/student_profile");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const onAcademicSubmit = () => {
    setIsLoading(true);

    instance
      .post("/Students/CourseWork", state)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateTab(4));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };
  return (
    <>
      {/* {isLoading ? (
        <ActivityIndicator />
      ) : ( */}
      <div>
        <div className="my-2 border-b-2 p-2">
          <div className="font-semibold text-lg">Academic Details</div>
        </div>
        <div className="flex px-2 ">
          <div className="sm:flex  sm:space-x-10   ">
            <div className="w-full">
              <div className="my-1 ">
                <Input
                  lable="Subject I"
                  type="text"
                  name="SubjectI"
                  value={SubjectI}
                  onChange={handleChangeText}
                  error={formErrors.SubjectI}
                />
              </div>

              <div className="my-1">
                <Input
                  lable="Subject II"
                  type="text"
                  name="SubjectII"
                  value={SubjectII}
                  onChange={handleChangeText}
                  error={formErrors.SubjectII}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Subject III"
                  type="text"
                  name="SubjectIII"
                  value={SubjectIII}
                  onChange={handleChangeText}
                  error={formErrors.SubjectIII}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Subject IV"
                  type="text"
                  name="SubjectIV"
                  value={SubjectIV}
                  onChange={handleChangeText}
                  error={formErrors.SubjectIV}
                />
              </div>
              <div className="my-1 ">
                <Input
                  lable="Result of Coursework"
                  type="date"
                  name="ResultofCoursework"
                  value={ResultofCoursework}
                  onChange={handleChangeText}
                  error={formErrors.ResultofCoursework}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="IDCM Meeting Date"
                  type="date"
                  name="IDCMMeetingDate"
                  value={IDCMMeetingDate}
                  onChange={handleChangeText}
                  error={formErrors.IDCMMeetingDate}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="II DCM Meeting Date"
                  type="date"
                  name="IIDCMMeetingDate"
                  value={IIDCMMeetingDate}
                  onChange={handleChangeText}
                  error={formErrors.IIDCMMeetingDate}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="my-1 ">
                <Input
                  lable="I DCM Examination"
                  type="text"
                  name="IDCMExamination"
                  value={IDCMExamination}
                  onChange={handleChangeText}
                  error={formErrors.IDCMExamination}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="I DCM Remarks with Title"
                  type="text"
                  name="IDCMRemarkswithTitle"
                  value={IDCMRemarkswithTitle}
                  onChange={handleChangeText}
                  error={formErrors.IDCMRemarkswithTitle}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="II DCM Examiners"
                  type="text"
                  name="IIDCMExaminers"
                  value={IIDCMExaminers}
                  onChange={handleChangeText}
                  error={formErrors.IIDCMExaminers}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="II DCM Remarks"
                  type="text"
                  name="IIDCMRemarks"
                  value={IIDCMRemarks}
                  onChange={handleChangeText}
                  error={formErrors.IIDCMRemarks}
                />
              </div>
            </div>
          </div>
        </div>
        {action.action === "edit" ? (
          <div className="flex items-center space-x-4 mt-5 px-2">
            <Button
              lable="Update"
              type="button"
              buttonType="primary"
              onClick={hadleSubmit}
            />
            <Button
              lable="cancel"
              type="button"
              buttonType="danger"
              onClick={() => navigate("/student/student_profile")}
            />
          </div>
        ) : (
          <div className="flex items-center space-x-4 mt-5 px-2">
            <Button
              lable="Submit"
              type="button"
              buttonType="primary"
              onClick={hadleSubmit}
            />
            <Button
              lable="cancel"
              type="button"
              buttonType="danger"
              onClick={() => navigate("/student/student_profile")}
            />
          </div>
        )}
      </div>
      {/* )} */}
    </>
  );
};

export default StudenAcademicform;
