import React, { useEffect, useState } from "react";
import bg from "../../assets/bgFront.jpg";
import logo from "../../assets/sr_logo.gif";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SnackBar from "../../components/SnackBar/SnackBar";
import { baseUrl } from "../../service/service";
import { useDispatch } from "react-redux";
import {
  updateIsLoading,
  updateRole,
  updateToken,
  updateUserInformation,
} from "../../redux/action";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginStatus, setLoginStatus] = useState({
    text: "Login",
    status: false,
  });
  const [state, setState] = useState({
    username: "",
    password: "",
    role: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const { username, password, role } = state;
  const [snackBarOpen, setSnackBarOpen] = useState({
    value: false,
    message: "",
    type: "",
  });

  const handleChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const validate = (state: any) => {
    const error: any = {};
    const emailvalidation = /\S+@\S+\.\S+/.test(username);

    if (!username) {
      error.username = "Please enter your username";
    } else if (!emailvalidation) {
      error.username = "Please enter valid username";
    }

    if (!password) {
      error.password = "Please enter your password";
    }
    if (!role) {
      error.role = "Please enter your role";
    }
    return error;
  };
  const handleSubmit = () => {
    setFormErrors(validate(state));
    setIsSubmit(true);
  };

  useEffect(() => {
    let obj = {
      UserName: username,
      Password: password,
      LoginAs: role,
      mobileNumber: "9874563210",
    };
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      doLogin(obj);
    }
  }, [formErrors]);

  const doLogin = (data: any) => {
    setLoginStatus({ ...loginStatus, text: "Please wait...", status: true });
    axios
      .post(`${baseUrl}/account/Login`, data)
      .then(function (response) {
        if (response.status === 200) {
          dispatch(updateToken(response.data.AccessToken));
          dispatch(updateUserInformation(response.data));
          dispatch(updateRole(role));
          if (role === "Student") {
            navigate("student/student_home");
          } else {
            navigate("guide/guide_home");
          }
          setLoginStatus({
            ...loginStatus,
            text: "Please wait...",
            status: false,
          });
        }
      })
      .catch(function (error) {
        setLoginStatus({
          ...loginStatus,
          text: "Login",
          status: false,
        });

        if (error) {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: "User is not Found!",
            type: "error",
          });
        }

        console.log("error", error);
      });
  };

  return (
    <div>
      <SnackBar
        isOpen={snackBarOpen.value}
        handleClose={() => setSnackBarOpen({ ...snackBarOpen, value: false })}
        message={snackBarOpen.message}
        type={snackBarOpen.type}
      />
      {loginStatus.status ? <ActivityIndicator /> : null}
      <div className="flex items-center justify-center sm:h-screen sm:shadow-lg rounded-lg sm:bg-[#02497f]">
        <div className="h-fit w-[900px] sm:flex justify-between bg-white sm:shadow-lg rounded-md">
          <div className="sm:w-1/2 h-full items-center bg-white rounded-md hidden sm:block">
            <img src={bg} className="rounded-tl-md rounded-bl-md p-2 mt-3" />
          </div>
          <div className="sm:w-1/2 p-6">
            <div className="flex justify-end mb-4">
              <div
                onClick={() => navigate("register")}
                className="p-2 border border-[#200175] rounded-full w-fit text-sm cursor-pointer text-[#200175]"
              >
                Create Account
              </div>
            </div>
            <div className="flex flex-col items-center  ">
              <img src={logo} height={100} width={100} />
              <div className="text-[30px] font-bold text-[#200175]">
                Srinivas University
              </div>
              <div className="text-[20px] text-[#200175]">Welcome Back</div>
              <div>
                <div className="text-sm text-[gray]">Email</div>
                <input
                  className="h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="John@gmail.com"
                  value={username}
                  name="username"
                  type="text"
                  onChange={(e: any) => handleChange(e)}
                />
                <div className="text-sm text-red-500">
                  {formErrors?.username}
                </div>
              </div>

              <div className="my-1">
                <div className="text-sm  text-[gray]">Password</div>
                <input
                  className="h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="*******"
                  value={password}
                  name="password"
                  type="password"
                  onChange={(e: any) => handleChange(e)}
                />
                <div className="text-sm text-red-500">
                  {formErrors?.password}
                </div>
              </div>
              <div className="my-1">
                <div className="text-sm  text-[gray]">Role</div>
                <select
                  name="role"
                  onChange={(e: any) => handleChange(e)}
                  className="h-8 text-sm w-[250px] sm:w-[300px] border text-blue-900 cursor-pointer rounded-sm pl-2 outline-none"
                >
                  <option value={""}>Select</option>
                  <option value={"Student"}>Student</option>
                  <option value={"Guide"}>Guide</option>
                </select>

                <div className="text-sm text-red-500">{formErrors?.role}</div>
              </div>
            </div>
            <div className="flex items-center w-full justify-end mt-2 text-sm pr-12 text-blue-900">
              <div>Forgot Password?</div>
            </div>
            <div className="flex items-center w-full justify-center mt-4">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="bg-[#200175]  px-4 py-1 rounded-sm text-white"
              >
                {loginStatus.text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
