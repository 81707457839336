import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../service/service";
import StudentFeeform from "./StudentFeeform";
import StudenAcademicform from "./StudenAcademicform";
import { updateIsLoading, updateTab } from "../../../redux/action";
import StudentReportfrom from "./StudentReportfrom";
import instance from "../../../service/axiosInstane";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";

const StudentDetailForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = useParams();

  const userinformation = useSelector((state: any) => state.userInfo);
  const tab = useSelector((state: any) => state.currentTab);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [studentDetails, setStudentDetails] = useState({
    CandName: "",
    CandId: userinformation.UserID,
    PermenentAddress: "",
    CommunicationAddress: "",
    AadharNo: "",
    PANNo: "",
    FatherOrHusbandName: "",
    Category: "",
    PhoneNumber: "",
    EmailId: "",
    NameOfQualifiedExam: "",
    CGPOrPercent: "",
    NameOfUniversity: "",
    YearOfExam: "",
    NEETOrSLETOrOthers: "",
    DateofEntranceExamination: "",
    RegistrationNo: "",
    SubmittedOn: true,
    DateOfExamination: "",
  });
  const {
    CandName,
    PermenentAddress,
    CommunicationAddress,
    AadharNo,
    PANNo,
    FatherOrHusbandName,
    Category,
    PhoneNumber,
    EmailId,
    NameOfQualifiedExam,
    CGPOrPercent,
    NameOfUniversity,
    YearOfExam,
    NEETOrSLETOrOthers,
    DateofEntranceExamination,
    RegistrationNo,
    DateOfExamination,
  } = studentDetails;

  const validateStudentDetails = () => {
    const errors: any = {};
    if (!CandName) {
      errors.CandName = "Please enter candidate name.";
    }
    if (!PermenentAddress) {
      errors.PermenentAddress = "Please enter permenentAddress.";
    }
    if (!CommunicationAddress) {
      errors.CommunicationAddress = "Please enter communicationAddress.";
    }
    if (!PANNo) {
      errors.PANNo = "Please enter PAN number. ";
    }
    if (!AadharNo) {
      errors.AadharNo = "Please enter aadhar number.";
    }
    if (!FatherOrHusbandName) {
      errors.FatherOrHusbandName = "Please enter father or husband name name.";
    }
    if (!Category) {
      errors.Category = "Please enter category.";
    }
    if (!EmailId) {
      errors.EmailId = "Please enter email.";
    }
    if (!NameOfQualifiedExam) {
      errors.NameOfQualifiedExam = "Please enter name of qualified exam.";
    }
    if (!CGPOrPercent) {
      errors.CGPOrPercent = "Please enter CGP Or percentage.";
    }
    if (!NameOfUniversity) {
      errors.NameOfUniversity = "Please enter name of university.";
    }
    if (!YearOfExam) {
      errors.YearOfExam = "Please enter year of exam.";
    }
    if (!NEETOrSLETOrOthers) {
      errors.NEETOrSLETOrOthers = "Please enter NEET or SLET or others.";
    }
    if (!DateofEntranceExamination) {
      errors.DateofEntranceExamination =
        "Please enter date of entrance examination.";
    }
    if (!RegistrationNo) {
      errors.RegistrationNo = "Please enter registration number.";
    }
    if (!DateOfExamination) {
      errors.DateOfExamination = "Please enter date of examination.";
    }
    return errors;
  };

  useEffect(() => {
    if (action.tab_id !== "new_entry") {
      dispatch(updateTab(Number(action.tab_id)));
    }
  }, [window.location.href]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (action.action === "edit") {
        studentBasicDetailsUpdate();
      } else {
        submitBasicinformation();
      }
    }
  }, [formErrors]);

  useEffect(() => {
    setIsLoading(true);
    if (action.action === "edit") {
      getBasicDetails();
      setIsLoading(false);
    } else {
      profileInformation();
      setIsLoading(false);
    }
  }, [window.location.href]);

  const handleChangeText = (e: any) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };

  const hadleSubmit = () => {
    setFormErrors(validateStudentDetails());
    setIsSubmit(true);
  };

  const getBasicDetails = async () => {
    setIsLoading(true);
    await instance
      .get(`/Students/GetStudentDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setStudentDetails(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const profileInformation = async () => {
    setIsLoading(true);

    await instance
      .post("/account/GetUserProfile", { ID: userinformation.UserID })
      .then((response) => {
        setStudentDetails({
          ...studentDetails,
          CandName: response.data.UserName,
          EmailId: response.data.EmailID,
          PhoneNumber: response.data.PhoneNumber,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const submitBasicinformation = async () => {
    setIsLoading(true);
    await instance
      .post("/Students/StudentDetails", studentDetails)
      .then((response) => {
        if (response.status === 200) {
          console.log("basic", response);
          dispatch(updateTab(2));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const studentBasicDetailsUpdate = async () => {
    setIsLoading(true);

    await instance
      .post(
        `/Students/StudentDetailsUpdated?UserId=${userinformation.UserID}`,
        studentDetails
      )
      .then((response) => {
        if (response.status === 200) {
          alert("update success");
          navigate("/student/student_profile");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("something went wrong!");
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const studentBasicInformation = () => {
    return (
      <div>
        <div className="my-1 border-b p-2">
          <div className="font-semibold text-lg">Student Details</div>
        </div>
        <div className="flex px-2">
          <div className="sm:flex sm:space-x-10   ">
            <div className="w-full">
              <div className="my-1 ">
                <Input
                  lable="Candidate Name"
                  type="text"
                  name="CandName"
                  value={CandName}
                  onChange={handleChangeText}
                  error={formErrors.CandName}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Permanent Address"
                  type="text"
                  name="PermenentAddress"
                  value={PermenentAddress}
                  onChange={handleChangeText}
                  error={formErrors.PermenentAddress}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Address for Communication"
                  type="text"
                  name="CommunicationAddress"
                  value={CommunicationAddress}
                  onChange={handleChangeText}
                  error={formErrors.CommunicationAddress}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Aadhaar No"
                  type="text"
                  name="AadharNo"
                  value={AadharNo}
                  onChange={handleChangeText}
                  error={formErrors.AadharNo}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="PAN No"
                  type="text"
                  name="PANNo"
                  value={PANNo}
                  onChange={handleChangeText}
                  error={formErrors.PANNo}
                />
              </div>
              <div className="my-1 ">
                <Input
                  lable="Father’s/Husband’s Name"
                  type="text"
                  name="FatherOrHusbandName"
                  value={FatherOrHusbandName}
                  onChange={handleChangeText}
                  error={formErrors.FatherOrHusbandName}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Category"
                  type="text"
                  name="Category"
                  value={Category}
                  onChange={handleChangeText}
                  error={formErrors.Category}
                />
              </div>
              <div className="my-1 ">
                <Input
                  lable="Phone No"
                  type="text"
                  name="PhoneNumber"
                  value={PhoneNumber}
                  onChange={handleChangeText}
                  error={formErrors.PhoneNumber}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Email"
                  type="text"
                  name="EmailId"
                  value={EmailId}
                  onChange={handleChangeText}
                  error={formErrors.EmailId}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="my-1">
                <Input
                  lable="Name of Qualified Exam"
                  type="text"
                  name="NameOfQualifiedExam"
                  value={NameOfQualifiedExam}
                  onChange={handleChangeText}
                  error={formErrors.NameOfQualifiedExam}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="CGPA / %"
                  type="text"
                  name="CGPOrPercent"
                  value={CGPOrPercent}
                  onChange={handleChangeText}
                  error={formErrors.CGPOrPercent}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Name of the University"
                  type="text"
                  name="NameOfUniversity"
                  value={NameOfUniversity}
                  onChange={handleChangeText}
                  error={formErrors.NameOfUniversity}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Year of Exam"
                  type="text"
                  name="YearOfExam"
                  value={YearOfExam}
                  onChange={handleChangeText}
                  error={formErrors.YearOfExam}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="NET/SLET/Other"
                  type="text"
                  name="NEETOrSLETOrOthers"
                  value={NEETOrSLETOrOthers}
                  onChange={handleChangeText}
                  error={formErrors.NEETOrSLETOrOthers}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Date of Entrance Examination"
                  type="date"
                  name="DateofEntranceExamination"
                  value={DateofEntranceExamination}
                  onChange={handleChangeText}
                  error={formErrors.DateofEntranceExamination}
                />
              </div>
              <div className="my-1">
                <Input
                  lable="Registration No"
                  type="text"
                  name="RegistrationNo"
                  value={RegistrationNo}
                  onChange={handleChangeText}
                  error={formErrors.RegistrationNo}
                />
              </div>

              <div className="my-1">
                <Input
                  lable="Date of Registration"
                  type="date"
                  name="DateOfExamination"
                  value={DateOfExamination}
                  onChange={handleChangeText}
                  error={formErrors.DateOfExamination}
                />
              </div>
            </div>
          </div>
        </div>
        {action.action === "edit" ? (
          <div className="flex items-center space-x-4 px-2 mt-5">
            <Button
              lable="Update"
              type="button"
              buttonType="primary"
              onClick={() => hadleSubmit()}
            />
            <Button
              lable="cancel"
              type="button"
              buttonType="danger"
              onClick={() => navigate("/student/student_profile")}
            />
          </div>
        ) : (
          <div className="flex items-center space-x-4 px-2 mt-5">
            <Button
              lable="Submit"
              type="button"
              buttonType="primary"
              onClick={() => hadleSubmit()}
            />
            <Button
              lable="cancel"
              type="button"
              buttonType="danger"
              onClick={() => navigate("/student/student_profile")}
            />{" "}
          </div>
        )}
      </div>
    );
  };

  return (
    // <>
    //   {isLoading ? (
    //     <ActivityIndicator />
    //   ) : (
    <div className="bg-white shadow-lg p-4">
      <div className="font-semibold text-[24px] text-sky-700">
        Student Profile Form
      </div>
      <div className="flex items-center justify-between">
        <div className={`bg-[#02497f] w-[80px] h-6 rounded-full `}></div>

        <div
          className={`${
            tab === 2 || tab === 3 || tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
          } w-full h-1  `}
        ></div>
        <div
          className={`${
            tab === 2 || tab === 3 || tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
          } w-[80px] h-6 rounded-full `}
        ></div>

        <div
          className={`${
            tab === 3 || tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
          } w-full h-1  `}
        ></div>
        <div
          className={`${
            tab === 3 || tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
          } w-[80px] h-6 rounded-full `}
        ></div>

        <div
          className={`${tab === 4 ? "bg-[#02497f]" : "bg-[gold]"} w-full h-1  `}
        ></div>

        <div
          className={`${
            tab === 4 ? "bg-[#02497f]" : "bg-[gold]"
          }  w-[80px] h-6 rounded-full `}
        ></div>
      </div>
      {tab === 1 && studentBasicInformation()}
      {tab === 2 && <StudentFeeform />}
      {tab === 3 && <StudenAcademicform />}
      {tab === 4 && <StudentReportfrom />}
    </div>
    //   )}
    // </>
  );
};

export default StudentDetailForm;
