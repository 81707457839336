import React from "react";
import "./activityIndicator.css";
import {
  Bars,
  BallTriangle,
  Circles,
  Grid,
  Hearts,
  Oval,
  Puff,
  Rings,
  SpinningCircles,
  TailSpin,
  ThreeDots,
} from "react-loading-icons";

const ActivityIndicator = () => {
  return (
    <div className="flex items-center justify-center backdrop">
      <Oval />
    </div>
  );
};

export default ActivityIndicator;
