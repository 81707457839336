import logo from "../../assets/banner.png";
import user from "../../assets/msd.jpg";
import { FaAngleDown, FaUserCircle, FaSignOutAlt, FaKey } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import Popover from "@mui/material/Popover";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import ResponsiveSidebar from "../ResponsiveSidebar/ResponsiveSidebar";
import { colors } from "../../config/constants";
import { useSelector } from "react-redux";
import { persistor } from "../../redux/store";

const Header = () => {
  const navigate = useNavigate();
  const username = useSelector((state: any) => state.userInfo.UserName);

  const profileData = [
    // {
    //   name: "Change Password",
    //   url: "/changepassword",
    //   icon: <FaKey className="mr-2" />,
    //   onClick: () => handleChangePassword(),
    // },
    {
      name: "Logout",
      url: "/",
      icon: <FaSignOutAlt className="mr-2" />,
      onClick: () => handleLogOut(),
    },
  ];
  const shortcuts = [
    {
      name: "aaaa",
      url: "profile",
      icon: <FaUserCircle className="mr-2" />,
    },
    {
      name: "bbbb",
      url: "profile",
      icon: <FaKey className="mr-2" />,
    },
    {
      name: "cccc",
      url: "profile",
      icon: <FaSignOutAlt className="mr-2" />,
    },
  ];
  const [profileanchorEl, setProfileAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [shortcutanchorEl, setShotCutAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleShortCutClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShotCutAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };
  const handleShortcutClose = () => {
    setShotCutAnchorEl(null);
  };
  const openprofile = Boolean(profileanchorEl);
  const openshortcut = Boolean(shortcutanchorEl);
  const id_profile = openprofile ? "simple-popover" : undefined;
  const id_shortcut = openshortcut ? "simple-popover2" : undefined;

  const onClickProfile = (url: string) => {
    handleClose();
    navigate(url);
  };

  const handleLogOut = async () => {
    await persistor.purge();

    navigate("/");
  };
  const handleChangePassword = () => {
    navigate("/changepassword");
    handleClose();
  };
  return (
    <div className="h-16 w-full flex items-center justify-between shadow-sm bg-[#02497f] z-10 border-b border-[#02134d]">
      <div className="flex items-center  sm:justify-start cursor-pointer ">
        <ResponsiveSidebar />
        <div className="sm:w-60">
          <img src={logo} className="h-[60px]  text-lg sm:ml-6" />
        </div>
        <div className="text-white text-[26px] font-bold ml-4 hidden sm:block">
          Srinivas University
        </div>
      </div>

      <div className="h-16 sm:w-96 flex items-center sm:justify-between justify-end">
        <div className="h-16 w-1/2 sm:flex hidden items-center justify-center text-[#02134d] border-r-2 border-slate-300">
          <button className="flex items-center" onClick={handleShortCutClick}>
            <IoIosNotifications
              color="white"
              size={25}
              className="ml-2 cursor-pointer "
            />
          </button>{" "}
        </div>

        <div>
          <button
            className="flex sm:items-center text-white"
            onClick={handleClick}
          >
            <div className="sm:flex hidden text-sm uppercase">{username}</div>
            <div>
              <img
                src={user}
                height={40}
                width={40}
                className="rounded-full mx-4 "
              />
            </div>
          </button>{" "}
          <div>
            <Popover
              className="mt-[14px] mr-2 ml-0 cursor-pointer"
              id={id_profile}
              open={openprofile}
              anchorEl={profileanchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="w-[180px] text-slate-900">
                {profileData.map((item, index) => {
                  return (
                    <div
                      onClick={item.onClick}
                      key={index}
                      className={`flex items-center p-2 border-b-2 border-slate-100 hover:${colors.primary} hover:text-white`}
                    >
                      {item.icon}
                      <div className="text-sm">{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
