import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import instance from "../../../service/axiosInstane";
import Button from "../../../components/Button/Button";
import studentPhoto from "../../../assets/guide.png";
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator";
import { update_breadcrumb } from "../../../redux/action";

const GuideProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileStatus, setProfileStatus] = useState<any>();
  const userinformation = useSelector((state: any) => state.userInfo);
  const [state, setState] = useState<any>({
    Aadhaar: "",
    Address: "",
    Cand_id: "",
    DOJ: "",
    DOR: "",
    GuideId: "",
    Name: "",
    PAN: "",
    Qualification: "",
    areaOfresearch: "",
    emailId: "",
    googleScholarId: "",
    mobileNumber: "",
    numberOfpublications: "",
    orchidId: "",
    totalExperience: "",
    vidwanId: "",
    yearOfaward: "",
  });
  const [isLoading, setIsLoading] = useState<any>(true);

  useEffect(() => {
    const breadcrumb = [
      { path: "/guide/guide_profile", name: "Guide Profile" },
    ];
    dispatch(update_breadcrumb(breadcrumb));
    GetGuideDetails();
  }, []);
  const GetGuideDetails = () => {
    setIsLoading(true);
    instance
      .get(`/Guide/GetGuideDetails?UserId=${userinformation.UserID}`)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
          console.log("guideDetails", response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <div className="bg-white shadow-lg p-4">
          <div className="px-1">
            <div className="font-semibold text-[24px] text-sky-700 py-1  border-b-2 border-[gold]">
              Guide Profile
            </div>
          </div>

          {!state.Aadhaar && !state.Qualification ? (
            <div>Sorry! Profile is not Updated!</div>
          ) : (
            <div>
              <div className="mt-2 sm:flex sm:space-x-6 text-[#02497f]">
                <div>
                  <img src={studentPhoto} className="h-36 border-2 p-2" />
                </div>
                <div>
                  <div className="flex space-x-2">
                    <div className="font-semibold  w-48">Name</div>
                    <div>: {state.Name}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Email </div>
                    <div>: {state.emailId}</div>
                  </div>{" "}
                  {/* <div className="flex space-x-2 mt-1">
                  <div className="font-semibold w-48">Guide Id</div>
                  <div>: {state.GuideId}</div>
                </div> */}
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Qualification</div>
                    <div>: {state.Qualification}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">PAN</div>
                    <div>: {state.PAN}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Aadhaar</div>
                    <div>: {state.Aadhaar}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Adress</div>
                    <div>: {state.Address}</div>
                  </div>
                </div>
                <div>
                  <div className="flex space-x-2 ">
                    <div className="font-semibold  w-48">Date of Joining</div>
                    <div>: {state.DOJ}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">
                      Date of Recognition
                    </div>
                    <div>: {state.DOR}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">
                      Number Of Publications
                    </div>
                    <div>: {state.numberOfpublications}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold w-48">Google Scholar Id</div>
                    <div>: {state.googleScholarId}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Vidwan Id</div>
                    <div>: {state.vidwanId}</div>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="my-1 p-1 border-b-2">
                <div className="font-semibold text-md">More Information</div>
              </div>
              <div className="sm:flex">
                <div className="text-[#02497f] px-1 sm:w-1/2">
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Mobile Number</div>
                    <div>: {state.mobileNumber}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Total Experience</div>
                    <div>: {state.totalExperience}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Orchid Id</div>
                    <div>: {state.orchidId}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Area of Reasearch</div>
                    <div>: {state.areaOfresearch}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Email </div>
                    <div>: {state.emailId}</div>
                  </div>{" "}
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Year Of Award</div>
                    <div>: {state.yearOfaward}</div>
                  </div>
                  <div className="flex space-x-2 mt-1">
                    <div className="font-semibold  w-48">Vidwan Id</div>
                    <div>: {state.vidwanId}</div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end">
                <Button
                  lable="Edit details"
                  type="button"
                  buttonType="primary"
                  onClick={() => navigate("/guide/guide_details_form/edit")}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GuideProfile;
