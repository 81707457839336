import axios from "axios";
import { store } from "../redux/store";

const instance = axios.create({
  baseURL: "http://103.167.211.212:81/api",
});

instance.interceptors.request.use(function (config) {
  const token = store.getState().token;
  config.headers.Authorization = token ? `Bearer ${token}` : "No token";
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
