import React, { useEffect, useState } from "react";
import bg from "../../assets/bgFront.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../service/service";
import SnackBar from "../../components/SnackBar/SnackBar";
const Register = () => {
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState("Register");

  const [state, setState] = useState({
    username: "",
    email: "",
    password: "",
    phonenumber: "",
    role: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const { username, email, phonenumber, password, role } = state;
  const [snackBarOpen, setSnackBarOpen] = useState({
    value: false,
    message: "",
    type: "",
  });

  const handleChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const validate = () => {
    const error: any = {};
    const emailvalidation = /\S+@\S+\.\S+/.test(email);
    if (!username) {
      error.username = "Please enter your username";
    }
    if (!password) {
      error.password = "Please enter your password";
    } else if (password.length < 6) {
      error.password = "Password must be minimum 6 characters";
    }
    if (!email) {
      error.email = "Please enter your email";
    } else if (!emailvalidation) {
      error.email = "Please enter valid email";
    }
    if (!phonenumber) {
      error.phonenumber = "Please enter your phonenumber";
    }
    if (!role) {
      error.role = "Please select your role";
    }
    return error;
  };
  const handleSubmit = () => {
    setFormErrors(validate());
    setIsSubmit(true);
  };

  useEffect(() => {
    let obj = {
      UserName: username,
      Email: email,
      Password: password,
      PhoneNumber: phonenumber,
      Role: role,
      GuideId: "",
      Status: true,
      CreatedOn: new Date(),
      UpdatedDate: new Date(),
    };
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(state);
      doRegister(obj);
    }
  }, [formErrors]);
  const doRegister = async (data: any) => {
    setLoginStatus("Please wait...");
    await axios
      .post(`${baseUrl}/account/Registration`, data)
      .then(function (response) {
        console.log("responce", response);
        if (response.status === 200) {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: "Registration successful",
            type: "success",
          });
          navigate("/");
        }
      })
      .catch(function (error) {
        setLoginStatus("Register");

        if (error)
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: error.response.data,
            type: "error",
          });
        console.log("err", error.response.data);
      });
  };
  return (
    <div>
      <SnackBar
        isOpen={snackBarOpen.value}
        handleClose={() => setSnackBarOpen({ ...snackBarOpen, value: false })}
        message={snackBarOpen.message}
        type={snackBarOpen.type}
      />
      <div className="flex items-center justify-center sm:h-screen sm:shadow-lg rounded-lg sm:bg-[#02497f]">
        <div className="h-fit w-[900px] sm:flex justify-between bg-white sm:shadow-lg rounded-md">
          <div className="sm:w-1/2 h-full bg-white rounded-md hidden sm:block">
            <img src={bg} className="rounded-tl-md rounded-bl-md" />
          </div>
          <div className="sm:w-1/2 p-6">
            <div className="flex flex-col h-full items-center justify-center">
              <div className="text-[30px] font-bold text-[#200175]">
                Srinivas University
              </div>
              <div className="text-[20px] text-[#200175]">Please Register </div>
              <div>
                <div className="text-sm text-[gray]">Username</div>
                <input
                  className="h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="John@gmail.com"
                  name="username"
                  value={username}
                  onChange={(e: any) => handleChange(e)}
                />
                <div className="text-sm text-red-500">
                  {formErrors?.username}
                </div>
              </div>
              <div className="my-1">
                <div className="text-sm  text-[gray]">Email</div>
                <input
                  className="h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="*******"
                  name="email"
                  value={email}
                  onChange={(e: any) => handleChange(e)}
                />
                <div className="text-sm text-red-500">{formErrors?.email}</div>
              </div>
              <div className="my-1">
                <div className="text-sm  text-[gray]">Role</div>
                <select
                  name="role"
                  onChange={(e: any) => handleChange(e)}
                  className="h-8 text-sm w-[250px] sm:w-[300px] border text-blue-900 cursor-pointer rounded-sm pl-2 outline-none"
                >
                  <option value={""}>Select</option>
                  <option value={"Student"}>Student</option>
                  <option value={"Guide"}>Guide</option>
                </select>

                <div className="text-sm text-red-500">{formErrors?.role}</div>
              </div>

              <div className="my-1">
                <div className="text-sm  text-[gray]">Phone number</div>
                <input
                  className="h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="*******"
                  name="phonenumber"
                  value={phonenumber}
                  onChange={(e: any) => handleChange(e)}
                />
                <div className="text-sm text-red-500">
                  {formErrors?.phonenumber}
                </div>
              </div>
              <div className="my-1">
                <div className="text-sm  text-[gray]">Password</div>
                <input
                  className="h-8 text-sm w-[250px] sm:w-[300px] border rounded-sm pl-2 outline-none"
                  placeholder="*******"
                  name="password"
                  value={password}
                  type="password"
                  onChange={(e: any) => handleChange(e)}
                />
                <div className="text-sm text-red-500">
                  {formErrors?.password}
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="bg-[#200175] w-[100px] px-4 py-1 rounded-sm text-white mt-2"
              >
                {loginStatus}
              </button>
              <div
                onClick={() => navigate("/")}
                className="p-2 w-fit text-sm cursor-pointer text-[#200175]"
              >
                Back to Login
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
